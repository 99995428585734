/* eslint-disable @ngrx/no-typed-global-store */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../authentication.service';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable()
export class AzureTokenInterceptor implements HttpInterceptor {

  constructor(readonly authService: AuthenticationService){
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authService.token.pipe(
      filter((token: string | undefined): token is string => !!token),
      map((token: string) => ({
        token,
        appendToken: this.authService.resourceNeedsToken(req.url)
      })),
      switchMap(({ token, appendToken }) => {
        const clonedReq =
          token && appendToken
            ? req.clone({
                setHeaders: {
                  Authorization: `Bearer ${token}`
                }
              })
            : req;

        return next.handle(clonedReq);
      })
    );
  }
}
