import { Injectable, inject } from '@angular/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

import { AuthenticationService } from '../../../app/services/authentication.service';
import { environment } from '../../../environments/environment';
import { TokenDetails } from '../../models/auth/token-details.type';
import {
  ProfileFeature,
  ProfileRole,
  Tenant,
} from '../../models/auth/admin-tool.type';
import { AuthActions } from '../actions/auth.actions';
import { SystemActions } from '../actions/system.actions';
import { AppState } from '../states/app.state';
import {
  selectActiveTenant,
  selectTokenDetails,
} from '../selectors/auth.selectors';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  concatMap,
  delay,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class AuthEffects {
  private readonly authService: AuthenticationService = inject(
    AuthenticationService
  );
  private readonly actions$: Actions = inject(Actions);
  private readonly store: Store<AppState> = inject(Store);

  redirectToGateway$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.RedirectToGateway),
        tap(() => this.authService.login())
      );
    },
    { dispatch: false }
  );

  setActiveAccount$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.setActiveAccount),
        tap(() => this.authService.checkAndSetActiveAccount())
      );
    },
    { dispatch: false }
  );

  login$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.login),
        tap(() => this.authService.login())

      );
    },
    { dispatch: false }
  );

  logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => this.authService.logout())
      );
    },
    { dispatch: false }
  );

  changeActiveTenant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.setActiveTenant),
      tap(({ active_tenant }) =>
        localStorage.setItem(
          `${environment.localStorageKeyPrefix}_tenant`,
          active_tenant.name
        )
      ),
      concatLatestFrom(() => this.store.select(selectTokenDetails)),
      map(([{ active_tenant, isPageReload }, tokenDetails]) =>
        AuthActions.getUserRoles({
          uniqueId: tokenDetails?.uniqueId ?? '',
          tenant: active_tenant.name,
          isPageReload,
        })
      )
    );
  });

  changeActiveRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.setActiveRole),
      concatLatestFrom(() => this.store.select(selectActiveTenant)),
      map(([{ active_role, isPageReload }, tenant]) =>
        AuthActions.getUserFeatures({
          role: active_role.name,
          tenant,
          isPageReload,
        })
      )
    );
  });

  userTenants$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getUserTenants),
      switchMap(({ uniqueId }) =>
        this.authService.tenants(uniqueId).pipe(
          switchMap((tenants: Array<Tenant>) => {
            if (tenants.length) {
              const current_tenant: string | null = localStorage.getItem(
                `${environment.localStorageKeyPrefix}_tenant`
              );
              const active_tenant: Tenant = current_tenant
                ? { name: current_tenant, info: '' }
                : tenants[0];
              return [
                AuthActions.getUserTenantsSuccess({ uniqueId, tenants }),
                AuthActions.setActiveTenant({
                  active_tenant,
                  isPageReload: false,
                }),
              ];
            } else {
              return [
                AuthActions.setTokenDetails({ tokenDetails: <TokenDetails>{} }),
                SystemActions.RedirectToCourtesyPage(),
              ];
            }
          }),
          catchError((error: HttpErrorResponse) =>
            of(AuthActions.getUserTenantsFailure({ error }))
          )
        )
      )
    );
  });

  userTenantsFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          AuthActions.getUserTenantsFailure,
          AuthActions.getUserRolesFailure
        ),
        tap(({ error }) => {
          if (error.status === HttpStatusCode.NotFound) {
            // eslint-disable-next-line @ngrx/no-dispatch-in-effects
            this.store.dispatch(SystemActions.RedirectToCourtesyPage());
          }
        })
      );
    },
    { dispatch: false }
  );

  userRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getUserRoles),
      delay(200),
      switchMap(({ uniqueId, tenant, isPageReload }) =>
        this.authService.roles(uniqueId, tenant).pipe(
          map((roles: Array<ProfileRole>) => {
            if (roles.length) {
              return AuthActions.getUserRolesSuccess({
                roles,
                tenant,
                isPageReload,
              });
            } else {
              return SystemActions.RedirectToCourtesyPage();
            }
          }),
          catchError((error: HttpErrorResponse) =>
            of(AuthActions.getUserRolesFailure({ error }))
          )
        )
      )
    );
  });

  userRolesSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getUserRolesSuccess),
      map(({ roles, tenant, isPageReload }) =>
        AuthActions.getUserFeatures({
          role: roles?.[0].name ?? '',
          tenant,
          isPageReload,
        })
      )
    );
  });

  userFeatures$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getUserFeatures),
      switchMap(({ role, tenant, isPageReload }) =>
        this.authService.features(role, tenant).pipe(
          concatMap((features: Array<ProfileFeature>) => {
            const actions = [];
            if (features.length) {
              if (isPageReload) {
                actions.push(SystemActions.RedirectToHomePage());
              }
              actions.push(AuthActions.getUserFeaturesSuccess({ features }));
            } else {
              actions.push(SystemActions.RedirectToCourtesyPage());
            }
            return actions;
          }),
          catchError((error: HttpErrorResponse) =>
            of(AuthActions.getUserFeaturesFailure({ error }))
          )
        )
      )
    );
  });
}
