import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'error',
    loadChildren: () =>
      import('../pages/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: 'auditor-copilot',
    canActivate: [],
    loadChildren: () => import('../cube/cube.module').then((m) => m.CubeModule),
  },
  {
    path: '**',
    redirectTo: 'auditor-copilot',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    useHash: true
}),
  ],
  exports: [RouterModule],
})
export class LazyLoadModule {}
