import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MessageService } from '@cube/lib-common';
import { environment } from '../../environments/environment';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { ActivableCardComponent } from './activable-card/activable-card.component';
import { ActivableCardGroupDirective } from './activable-card/activable-card-group';
import { FullscreenComponent } from './fullscreen/fullscreen.component';
import { MaterialModule } from './material.module';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { SidemenuItemComponent } from './sidemenu-item/sidemenu-item.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { UserMenuComponent } from './user-menu/user-menu.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { LetDirective, PushPipe } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { LoggerModule } from 'ngx-logger';
import { MomentModule } from 'ngx-moment';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatSnackBar } from '@angular/material/snack-bar';
@NgModule({
  declarations: [
    ActivableCardComponent,
    ActivableCardGroupDirective,
    FullscreenComponent,
    SearchBarComponent,
    SidebarComponent,
    SidemenuComponent,
    SidemenuItemComponent,
    ToolbarComponent,
    UserMenuComponent,
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    FlexLayoutModule,
    FormsModule,
    LoggerModule.forRoot(environment.logger),
    MaterialModule,
    MomentModule,
    PipesModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    NgScrollbarModule,
    LetDirective
  ],
  exports: [
    ActivableCardComponent,
    ActivableCardGroupDirective,
    FlexLayoutModule,
    FormsModule,
    FullscreenComponent,
    LetDirective,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    SearchBarComponent,
    SidebarComponent,
    SidemenuComponent,
    SidemenuItemComponent,
    ToolbarComponent,
    TranslateModule,
    UserMenuComponent,
  ],
  providers: [
    MessageService,
    MatSnackBar
  ],
})
export class CoreModule {}
