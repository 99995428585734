import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { InputDialogComponent } from '@cube/lib-common';
import { environment } from '../../../environments/environment';
import { User } from '../../models/auth/admin-tool.type';
import { CubeService } from '../../services/cube/cube.service';
import { LanguageService } from '../../services/language/language.service';
import { STORAGE_KEYS } from '../../services/storage/storage.service';
import { AuthActions } from '../../store/actions/auth.actions';

// import {  MatDialog } from '@angular/material/dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Store } from '@ngrx/store';
import { SystemActions } from '../../store/actions/system.actions';
@Component({
  selector: 'cube-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  @Output() onDepartmentClick: EventEmitter<any> = new EventEmitter<any>();

  @Input() user: User = null;

  activeRole: string;
  activeTenant: string;
  enableAdminTool: boolean;
  enableAppMobile: boolean;
  enableMailingList: boolean;
  enableTenantChange: boolean;
  isOpen = false;
  language: string = 'English';

  constructor(
    public dialog: MatDialog,
    private cubeService: CubeService,
    private elementRef: ElementRef,
    private languageService: LanguageService,
    private store: Store
  ) {}

  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.isOpen = false;
    }
  }

  ngOnInit(): void {
    this.activeRole = this.user.active_role.name;
    this.activeTenant = this.user.active_tenant.name;
    this.enableTenantChange = this.user.tenants.length > 1;
    this.language = this.languageService.getLanguage();
  }

  doLogout(): void {
    this.store.dispatch(AuthActions.logout());
  }

  goAdminTool(): void {
    window.open(environment.ADMIN_TOOL_WEB, '_blank');
  }

  changeTheme(): void {
    this.store.dispatch(SystemActions.ChangeTheme());
  }

  changeProfile(): void {
    const dialogRef = this.dialog.open(InputDialogComponent, {
      width: '400px',
      height: '200px',
      data: {
        title: 'Roles selection',
        confirmLabel: 'Ok',
        cancelLabel: 'Cancel',
        inputs: [
          {
            type: 'select',
            label: 'Select department',
            required: true,
            options: this.user.roles.map((role) => role.name),
            value: this.user.active_role.name,
          },
        ],
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirm) {
        this.onDepartmentClick.emit();
        const active_role = this.user.roles.find((role) => {
          return role.name == result.data.inputs[0].value;
        });
        this.store.dispatch(
          AuthActions.setActiveRole({ active_role, isPageReload: true })
        );
      }
    });
  }

  changeLang(event: string): void {
    this.language = event;
    this.languageService.setLanguage(event);
  }

  changeTenant(): void {
    const dialogRef = this.dialog.open(InputDialogComponent, {
      width: '400px',
      height: '200px',
      data: {
        title: 'Tenant selection',
        confirmLabel: 'Ok',
        cancelLabel: 'Cancel',
        inputs: [
          {
            type: 'select',
            label: 'Select tenant',
            required: true,
            options: this.user.tenants.map((tenant) => tenant.name),
            value: this.user.active_tenant.name,
            fieldDisplayed: '',
          },
        ],
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirm) {
        this.cubeService.cleanCache();
        const active_tenant = this.user.tenants.find((tenant) => {
          return tenant.name == result.data.inputs[0].value;
        });
        this.store.dispatch(
          AuthActions.setActiveTenant({ active_tenant, isPageReload: true })
        );
      }
    });
  }

  public openSupportPage(): void {
    window.open('https://support.cube.rina.org', '_blank');
  }
}
