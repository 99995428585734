import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';

import { Theme } from './models/cube/theme.type';
import { CubeService } from './services/cube/cube.service';
import { STORAGE_KEYS } from './services/storage/storage.service';
import { selectTheme } from './store/selectors/system.selectors';
import { AppState } from './store/states/app.state';
import { TokenService } from './services/token.service';
import { AuthActions } from './store/actions/auth.actions';
import { AuthenticationService } from './services/authentication.service';
import { TokenDetails } from './models/auth/token-details.type';
import { concatLatestFrom } from '@ngrx/effects';
import { selectTokenDetails, selectUserTenants } from './store/selectors/auth.selectors';

import { Store } from '@ngrx/store';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cube-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [],
})
export class AppComponent implements OnInit, OnDestroy {
  isDarkTheme: boolean = false;

  private destroy$ = new Subject<void>();
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly authService: AuthenticationService,
    private overlayContainer: OverlayContainer,
    private readonly store: Store<AppState>,
    private readonly tokenService: TokenService
  ) {
    this.store.dispatch(AuthActions.login());

    this.authService.token.pipe(
      filter((token: string | undefined): token is string => !!token)).subscribe(token => {
        const uniqueId = this.tokenService.getUniqueId(token);
        this.store.dispatch(AuthActions.setTokenDetails({tokenDetails: {idToken: token, uniqueId} as TokenDetails}))})

        this.store
        .select(selectTokenDetails)
        .pipe(
          filter((tokenDetails: TokenDetails | undefined): tokenDetails is TokenDetails => !!tokenDetails),
          concatLatestFrom(() => this.store.select(selectUserTenants)),
          takeUntil(this.destroy$)
        )
        .subscribe({
          next: ([tokenDetails, tenants]) => {
            if (!tenants.length) {
              this.store.dispatch(AuthActions.getUserTenants({ uniqueId: tokenDetails.uniqueId }));
            }
          },
        });
  }

  getRouteAnimation(outlet) {
    return outlet.activatedRouteData.animation;
  }

  ngOnInit() {
    this.store
      .select<Theme>(selectTheme)
      .pipe(map((theme: Theme): boolean => theme === 'dark'))
      .subscribe({
        next: (isDark: boolean) => {
          this.isDarkTheme = isDark;

          this.overlayContainer
            .getContainerElement()
            .classList.remove(isDark ? 'light-theme' : 'dark-theme');
          this.overlayContainer
            .getContainerElement()
            .classList.add(!isDark ? 'light-theme' : 'dark-theme');
        },
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.destroy$.next();
    this.destroy$.complete();
  }
}
