import { HttpErrorResponse } from '@angular/common/http';

import { Tenant } from '../../models/auth/admin-tool.type';
import { TokenDetails } from 'src/app/models/auth/token-details.type';

import { createAction, props } from '@ngrx/store';

export const AuthActions = {
  login: createAction('[Auth] Login'),
  loginSuccess:createAction(
    '[Auth] LoginSuccess',
    props<{ tokenDetails: any }>()
  ),
  logout: createAction('[Auth] Logout'),
  setActiveAccount: createAction('[Auth] Set Active Account'),
  setTokenDetails: createAction(
    '[Auth] Set Token Details',
    props<{ tokenDetails: any }>()
  ),
  RedirectToGateway: createAction('[Auth]  RedirectToGateway'),
  getUserTenants: createAction(
    '[Auth] Get User Tenants',
    props<{ uniqueId: string }>()
  ),
  getUserTenantsSuccess: createAction(
    '[Auth] Get User Tenants Success',
    props<{ uniqueId: string; tenants: Array<Tenant> }>()
  ),
  getUserTenantsFailure: createAction(
    '[Auth] Get User Tenants Failure',
    props<{ error: HttpErrorResponse }>()
  ),
  getUserRoles: createAction(
    '[Auth] Get User Roles',
    props<{ uniqueId: string; tenant: string; isPageReload: boolean }>()
  ),
  getUserRolesSuccess: createAction(
    '[Auth] Get User Roles Success',
    props<{ roles: Array<any>; tenant: string; isPageReload: boolean }>()
  ),
  getUserRolesFailure: createAction(
    '[Auth] Get User Roles Failure',
    props<{ error: HttpErrorResponse }>()
  ),
  getUserFeatures: createAction(
    '[Auth] Get User Features',
    props<{ role: string; tenant: string; isPageReload: boolean }>()
  ),
  getUserFeaturesSuccess: createAction(
    '[Auth] Get User Features Success',
    props<{ features: Array<any> }>()
  ),
  getUserFeaturesFailure: createAction(
    '[Auth] Get User Features Failure',
    props<{ error: HttpErrorResponse }>()
  ),
  setActiveRole: createAction(
    '[Auth] Set Active Role',
    props<{ active_role: any; isPageReload: boolean }>()
  ),
  setActiveTenant: createAction(
    '[Auth] Set Active Tenant',
    props<{ active_tenant: Tenant; isPageReload: boolean }>()
  ),
};
